import React from 'react';
import PropTypes from 'prop-types';
import { Helmet as HM } from 'react-helmet';
import { flatten, get, isEmpty, startCase } from 'lodash';
import { showFilters } from '~/helper';
import { showFiltedCategory } from './Breadcrumb';
import { shouldAppendIndex } from './helper';

export default class Helmet extends React.Component {
  static propTypes = {
    category: PropTypes.shape({
      urlSlug: PropTypes.string,
      metaDescription: PropTypes.string,
    }),
    subcategory: PropTypes.shape({
      urlSlug: PropTypes.string,
    }),
    brand: PropTypes.shape({
      name: PropTypes.string,
      urlSlug: PropTypes.string,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    title: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    error: PropTypes.shape({
      status: PropTypes.number,
    }),
  }

  static defaultProps = {
    category: {},
    children: null,
    subcategory: {},
    brand: {},
    location: {},
    title: null,
    error: null,
  }

  getHelmetTitle = (category, subcategory, brand, location) => {
    const { pathname, query } = location;

    // if it is a category page and a title is set
    if (category.metaTitle && isEmpty(subcategory) && isEmpty(brand)) {
      return category.metaTitle;
    }

    // if it is a brand page(excluding pages with `filters` query in url) and a title is set
    if (isEmpty(subcategory) && brand && brand.metaTitle && isEmpty(location.query)) {
      return brand.metaTitle;
    }

    let brandTitle = '';
    let categoryTitle = '';
    const subcategoryTitle = [];

    let title = '';
    const jointer = ' | ';

    if (!isEmpty(brand)) {
      brandTitle = brand.name;
    }

    if (!isEmpty(subcategory)) {
      // if subcategory already has a predefined meta title, just return
      if (subcategory.metaTitle) {
        return `${subcategory.metaTitle} | Sydney Tools`;
      }

      const paths = pathname.split('/').filter(p => p);

      let firstSubcatIndex = null;

      // if there are subcategories in the url, depends on if this page
      // is under by-brand or not, return the first subcat index.
      //
      // For example,
      // /category/by-brand/dewalt/dewalt-power-tools/biscuit-jointers
      // /category/accessories-pta/abrasives/edge-disc
      // the idea is to locate `dewalt-power-tools` or `abrasives` and then startCase and add it
      // to the title
      if (!pathname.includes('by-brand') && paths.length === 4) {
        firstSubcatIndex = 2;
      } else if (pathname.includes('by-brand') && paths.length === 5) {
        firstSubcatIndex = 3;
      }

      if (firstSubcatIndex) {
        subcategoryTitle.push(startCase(paths[firstSubcatIndex]));
      }

      const { name } = subcategory;

      /*
       * If page is Makita / Makita 12V Max CXT, it will be converted to Makita Makita 12V Max CXT.
       * In order to prevent duplication, it is necessary to empty brandTitle
       */
      if (brand && name && name.toLowerCase().startsWith(brand.name.toLowerCase())) {
        brandTitle = '';
        subcategoryTitle.push(name);
      }
      else {
        subcategoryTitle.push(startCase(subcategory.urlSlug));
      }
    }

    // get subcategory from filters, for example, /category/by-brand/chicago-air?filters=%7B"category"%3A%20%5B"Air%20Compressors"%5D%7D
    // Home › Shop Tools By Brand › Chicago Air › Air Compressors
    const f = showFiltedCategory(subcategory, location);
    if (f) {
      subcategoryTitle.push(f);
    }

    // only if it is not THE `by-brand` category
    if (category.urlSlug !== 'by-brand') {
      categoryTitle = category.name;
    }

    // for pages like Hand Tools / Socket Sets / 1/2" Socket Sets
    const key = shouldAppendIndex(category, query);
    if (key) {
      const name = `${key} ${get(subcategory, 'name', '')}`;
      subcategoryTitle.push(name);
    }

    if (!isEmpty(brandTitle) && !isEmpty(subcategoryTitle)) {
      // rearrange the position of brandTitle to convert `Cleaning Brushes | 888 Tools` to `888 Tools Cleaning Brushes`.
      // When subcategoryTitle is in the form of ['Armor Shield', 'Water Hoses Reels'], it can be simply joined by a " " and displayed as
      // `Armor Shield Water Hoses & Reels`.
      // However, when it is in the form of ['Makita 18 V Lxt Skins', 'Angle Drills'], things are completely different.
      // It should be displayed as `Makita Angle Drills | Makita 18 V Lxt Skins`

      let subcat = [];

      // FIXME: using length as a condition is not reliable
      if (subcategoryTitle.length > 1) {
        // inject brand to subcategory
        subcategoryTitle[subcategoryTitle.length - 1] = `${brandTitle  } ${  subcategoryTitle[subcategoryTitle.length - 1]}`;

        subcat = [subcategoryTitle.reverse().join(jointer)];
      } else {
        subcategoryTitle.unshift(brandTitle);
        subcat = [subcategoryTitle.join(" ")];
      }

      title = ['Sydney Tools'].concat(categoryTitle, subcat).reverse().filter(i => i).join(jointer);
    } else {
      title = ['Sydney Tools'].concat(categoryTitle, brandTitle, flatten(subcategoryTitle)).reverse().filter(i => i).join(jointer);
    }

    return title;
  }

  getType = (category, subcategory, brand, location) => {
    const { pathname, search } = location;
    const query = new URLSearchParams(search);

    const catalogue = get(category, 'catalogues.edges[0].node', {});
    const catalogues = get(category, 'catalogues', {});

    const queryFilters = query.get("filters");
    // filter is for pages like: /category/accessories-pta/sockets/socket-mf-fm-adaptors
    const filters = showFilters(catalogues, queryFilters);

    if (pathname === "/") {
      return "home";
    }

    if (pathname.includes("/search")) {
      return "search";
    }

    if (pathname.includes("/category") && isEmpty(category)) {
      return "notFound";
    }

    if (pathname.includes("/category") && !isEmpty(category)) {
      if (isEmpty(catalogue) && catalogues.totalCount === 0) {
        return "notFound";
      } else if (queryFilters && shouldAppendIndex(category, {filters: queryFilters})) {
        return "filteredProductList";
      } else if (filters.length === 0 && (catalogue.__typename === 'Product' || catalogue.__typename === undefined)) {
        return "productList";
      } else if (filters.length > 0 && catalogue.__typename === 'Product') {
        return "subcategory";
      } else if (!isEmpty(subcategory) || !isEmpty(brand) && catalogue.__typename === "Subcategory") {
        return "subcategory";
      }
      return "category";
    }

    // Other pages that does not belong to home / search / category
    return null;
  }

  getUrl = (list, location) => {
    const { origin, pathname, search } = location;
    const query = new URLSearchParams(search);
    const queryFilters = query.get("filters");
    const page = query.get("p");
    const searchParams = new URLSearchParams();

    list.forEach(l => {
      if (l === "filters" && queryFilters) {
        searchParams.append("filters", queryFilters);
      } else if (l ==="p" && page && parseInt(page, 10) > 1) {
        searchParams.append("p", page)
      }
    });

    if (searchParams.toString() !== "") {
      return `${origin + pathname}?${searchParams.toString()}`;
    }

    return (origin + pathname);
  }

  getCanonicalLink = (category, subcategory, brand, location) => {
    const { origin, pathname } = location;
    const type = this.getType(category, subcategory, brand, location);
    const canonicalUrl = get(subcategory, 'canonicalUrl');

    switch (type) {
      case "home":
        return origin;

      case "search":
        return null;

      case "category":
        return (origin + pathname);

      case "subcategory":
        if (canonicalUrl) {
          return (origin + canonicalUrl);
        }
        return (origin + pathname);

      case "productList": {
        const current = this.getUrl(["p"], location);
        if (canonicalUrl) {
          const canonical = new URL(origin + canonicalUrl);
          canonical.search = (new URL(current)).search;
          return this.getUrl(["p"], canonical);
        }
        return current;
      }

      case "filteredProductList":
        return this.getUrl(["filters", "p"], location);

      case "notFound":
        return null;

      // eslint-disable-next-line no-fallthrough
      default:
        return (origin + pathname);
    }
  }

  renderCanonicalLink = (error, category, subcategory, brand, location) => {
    const url = this.getCanonicalLink(category, subcategory, brand, location);
    const status = get(error, "status");

    if (status !== 404 && url) {
      return (
        <link rel="canonical" href={url} />
      )
    }

    return null;
  }

  renderMetaDescription = (subcategory, category, brand) => {
    if (subcategory && subcategory.metaDescription) {
      return (
        <meta name="description" content={subcategory.metaDescription} />
      )
    } else if (category && category.metaDescription) {
      return (
        <meta name="description" content={category.metaDescription} />
      )
    }

    let desc = null;
    const store = 'Sydney Tools';

    if (subcategory && brand) {
      desc = `Buy ${brand.name} ${subcategory.name} at ${store}, Australia's leading trade tools retailer. Shop ${brand.name} products online or in-store today.`;
    } else if (subcategory) {
      desc = `Buy ${subcategory.name} at ${store}, Australia's leading trade tools retailer. Shop ${subcategory.name} online or in-store for the biggest range at the best`;
    } else if (brand) {
      desc = `Buy ${brand.name} supplies at ${store}, Australia's leading trade tools retailer. Shop ${brand.name} products online or in-store today`;
    } else if (category) {
      desc = `Buy ${category.name} at ${store}, Australia's leading trade tools retailer. Shop ${category.name} online or in-store for the biggest range at the best prices.`;
    }

    if (desc) {
      return <meta name="description" content={desc} />;
    }

    return null;
  }

  render() {
    const {
      children,
      category,
      error,
      subcategory,
      brand,
      location,
      title,
    } = this.props;

    let hmTitle = "";

    if (title) {
      hmTitle = title;
    } else {
      hmTitle = this.getHelmetTitle(category, subcategory, brand, location);
    }

    return (
      <HM title={hmTitle}>
        {!title && (
          this.renderMetaDescription(subcategory, category, brand)
        )}
        {this.renderCanonicalLink(error, category, subcategory, brand, window.location)}
        { children }
      </HM>
    );
  }
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, sortBy } from 'lodash';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import Headroom from 'react-headroom';
import MediaQuery from 'react-responsive'

import { FilterIcon } from '~/components/svg';
import { useStickyFilterProps } from '~/helper';
import Sorter from './Sorter';
import PriceFilter, { parseRange, getLabel, isPriceFilterApplied } from './PriceFilter';
import PriorityFilter from './PriorityFilter';
import FilterDropdown from './filter/FilterDropdown';
import FilterCheckbox from './filter/FilterCheckbox';
import FilterDrawer from './filter/FilterDrawer';

export const defaultKeys = ['category', 'brand', 'tool_type', 'subcategory'];
export const hiddenKey = ['price_stats', 'priority_shipping', 'private_labels'];

export const getBuckets = (filter, activeFilters) => {
  const { key } = filter;
  const active = get(activeFilters, key, []);

  const buckets = get(filter, 'buckets', []).map((bucket) => ({
    ...bucket,
    checked: active.indexOf(bucket.key) >= 0,
  }));

  return sortBy(buckets, [(o) => { return o.checked !== true }]);
}

const getIsFilterApplied = (key, activeFilters) => {
  const active = get(activeFilters, key, []);

  return active.length > 0;
}

const SelectedFilter = (props) => {
  const { activeFilters, updateFilters, resetFilters } = props;

  const filters = Object.keys(activeFilters).filter((f) => !hiddenKey.includes(f));

  if (isEmpty(filters)) {
    return null;
  }

  const filterList = filters.flatMap((key) => (
    get(activeFilters, key, []).map((value) => {
      let filterValue = value;

      if (key === 'price_range') {
        const [from, to] = parseRange(value);
        filterValue = getLabel(to, from);
      }

      return (
        <div
          key={`${key}_${value}`}
          style={{ background: '#F5F5F5', border: 'none', display: 'inline-block', fontSize: '14px', marginRight: '10px', paddingRight: '0px', paddingLeft: '10px' }}
        >
          {filterValue}
          <Button
            style={{ background: '#F5F5F5', border: 'none', padding: '0px', margin: '0px', height: '32px', width: '32px' }}
            onClick={() => { updateFilters(key, value, false); }}
          >
            <CloseSquareOutlined />
          </Button>
        </div>
      )
    })
  ));

  return (
    <div
      style={{
        paddingTop: '5px',
        paddingBottom: '5px',
        marginBottom: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <div style={{ flex: '1' }}>
        <span style={{ fontSize: '12px', marginRight: '10px' }}>
          <b>Selected Filters:</b>
        </span>
        {filterList}
      </div>
      <Button
        type="link"
        style={{ fontSize: '14px', padding: '0px', paddingLeft: '5px', marginRight: '10px' }}
        onClick={() => { resetFilters() }}
      >
        <b style={{ color: 'black' }}>Clear All Filters</b>
      </Button>
    </div>
  )
}

SelectedFilter.propTypes = {
  activeFilters: PropTypes.shape({}).isRequired,
  updateFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
}

const Filter = (props) => {
  const {
    match,
    filters,
    totalCount,
    activeFilters,
    orderBy,
    sort,
    configs,
    resetFilters,
    updateFilters,
    updatePriceRange,
    setCheckable,
  } = props;
  const showPriority= get(configs, 'priority', true);

  const [visible, setVisible] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const stickyFilterProps = useStickyFilterProps();

  const priorityFilter = filters.find(({ key }) => key === "priority_shipping");
  const priceFilter = filters.find(({ key }) => key === "price_stats");

  const numOfFilters = Object.values(activeFilters).reduce((acc, a) => {
    if (Array.isArray(a)) {
      return acc + a.length;
    } else if (typeof a === 'boolean') {
      return acc + 1
    }
    return acc;
  }, 0);

  const handleVisible = (v) => {
    setVisible(v);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const showPrivateFilter = () => {
    const internal = get(configs, 'internal', false);

    return window.location.pathname === '/new-arrivals' || internal;
  }

  return (
    <div id="filter-panel">
      <MediaQuery minWidth={576}>
        <>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', marginBottom: '5px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '32px', overflow: 'hidden' }}>
              <PriorityFilter
                showPriority={showPriority}
                match={match}
                filter={priorityFilter}
                activeFilters={activeFilters}
                setCheckable={setCheckable}
                style={{ display: 'inline-block', marginRight: '10px' }}
              />

              {showPrivateFilter() && (
                <Checkbox
                  onChange={e => {
                    setCheckable('private_labels', e);
                  }}
                >
                  Private Labels
                </Checkbox>
              )}

              <FilterDropdown
                key="sort_by"
                name="Sort By"
                style={{ display: 'inline-block', marginRight: '10px' }}
                isFilterApplied={false}
              >
                <Sorter
                  sort={sort}
                  filters={filters}
                  activeFilters={activeFilters}
                  orderBy={orderBy}
                  style={{ display: 'inline-block', marginRight: '10px', verticalAlign: 'bottom' }}
                  popover
                />
              </FilterDropdown>

              {priceFilter ? (
                <FilterDropdown
                  key="price_range"
                  filterKey="price_range"
                  name={priceFilter.name}
                  style={{ display: 'inline-block', marginRight: '10px' }}
                  isFilterApplied={isPriceFilterApplied(props)}
                >
                  <PriceFilter
                    filter={priceFilter}
                    activeFilters={activeFilters}
                    updatePriceRange={updatePriceRange}
                  />
                </FilterDropdown>
              ) : null}

              {filters.filter(({ key }) => defaultKeys.includes(key)).map((filter) => {
                const { key, name } = filter;
                const isFilterApplied = getIsFilterApplied(key, activeFilters);

                return (
                  <FilterDropdown
                    key={key}
                    filterKey={key}
                    name={name}
                    style={{ display: 'inline-block', marginRight: '10px' }}
                    isFilterApplied={isFilterApplied}
                  >
                    <FilterCheckbox
                      filterKey={key}
                      buckets={getBuckets(filter, activeFilters)}
                      updateFilters={updateFilters}
                      showSearch={["brand", "category", "subcategory"].includes(key)}
                      style={{ maxHeight: '314px', overflowY: 'auto' }}
                      checkboxContainerStyle={{ display: 'flex', flexDirection: 'column' }}
                      checkboxInnerStyle={{ fontSize: '14px', marginLeft: '0px', minHeight: '35px', alignItems: 'center' }}
                    />
                  </FilterDropdown>
                );
              })}
            </div>

            <Button
              type={numOfFilters ? "primary" : "default"}
              onClick={() => { handleVisible(true); }}
              style={{ padding: '0px 10px', fontSize: '14px' }}
            >
              <FilterIcon style={{ verticalAlign: 'middle', fontSize: '20px' }} />
              <b style={{ marginLeft: '5px' }}>All Filters</b>
            </Button>
          </div>

          <SelectedFilter
            activeFilters={activeFilters}
            updateFilters={updateFilters}
            resetFilters={resetFilters}
          />
        </>
      </MediaQuery>

      <MediaQuery maxWidth={575}>
        <Headroom
          className='mobile-filter'
          style={{ zIndex: 9 }}
          pinStart={stickyFilterProps.pinStart}
          disable={stickyFilterProps.disable}
        >
          <div style={{ display: 'flex', flexWrap: width < 360 ? 'wrap' : 'nowrap', margin: '5px 0px', flexDirection: 'row', justifyContent: 'space-between', padding: 0, background: 'unset' }}>
            <PriorityFilter
              showPriority={showPriority}
              match={match}
              filters={filters}
              activeFilters={activeFilters}
              setCheckable={setCheckable}
              style={{ display: 'inline-block', marginRight: '10px' }}
            />
            <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between', width: width < 360 ? '100%' : null }}>
              <FilterDropdown
                key="sort_by"
                name="Sort By"
                style={{ display: 'inline-block', marginRight: '10px' }}
                isFilterApplied={false}
              >
                <Sorter
                  sort={sort}
                  filters={filters}
                  activeFilters={activeFilters}
                  orderBy={orderBy}
                  style={{ display: 'inline-block', marginRight: '10px', verticalAlign: 'bottom' }}
                  popover
                />
              </FilterDropdown>
              <Button
                type={numOfFilters ? "primary" : "default"}
                onClick={() => { handleVisible(true); }}
                style={{ fontSize: '14px', maxWidth: '130px', width: '100%', padding: '0px 11px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}
              >
                <FilterIcon style={{ verticalAlign: 'middle', fontSize: '20px' }} />
                <b style={{ marginLeft: '5px' }}>Filters</b>
              </Button>
            </div>
          </div>
        </Headroom>
      </MediaQuery>

      <FilterDrawer
        match={match}
        totalCount={totalCount}
        filters={filters}
        activeFilters={activeFilters}
        showPriority={showPriority}
        updateFilters={updateFilters}
        updatePriceRange={updatePriceRange}
        setCheckable={setCheckable}
        resetFilters={resetFilters}
        handleVisible={handleVisible}
        visible={visible}
      />
    </div>
  )
}

Filter.propTypes = {
  match: PropTypes.shape({}).isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number.isRequired,
  activeFilters: PropTypes.shape({}).isRequired,
  orderBy: PropTypes.shape({
    field: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
  sort: PropTypes.func.isRequired,
  configs: PropTypes.shape({}),
  resetFilters: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  updatePriceRange: PropTypes.func.isRequired,
  setCheckable: PropTypes.func.isRequired,
}

Filter.defaultProps = {
  configs: {},
  orderBy: null,
}

export default Filter;
